@use '@angular/material' as mat;
@include mat.core();

$dark-primary-text: #001e30;
$light-primary-text: #ffffff;
$dark-secondary-text: #001f29;
$light-secondary-text: #fcfcff;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$pidg-palette: (
  50: #e6f2ff,
  100: #cbe6ff,
  200: #8ecdff,
  300: #5cb3f0,
  400: #3b98d4,
  500: #0b7eb8,
  600: #235374,
  700: #004b71,
  800: #00344f,
  900: #001e30,
  A100: #dff4ff,
  A200: #bce9ff,
  A400: #019dc6,
  A700: #004d63,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-secondary-text,
    A200: $dark-secondary-text,
    A400: $dark-secondary-text,
    A700: $light-secondary-text,
  )
);

$pidg-tertiary-palette: (
  50: #daffda,
  100: #b9ffb9,
  200: #9bf89b,
  300: #86ea86,
  400: #72d572,
  500: #55a955,
  600: #4a934a,
  700: #428642,
  800: #346c34,
  900: #295429,
  contrast: (
    50: $dark-secondary-text,
    100: $dark-secondary-text,
    200: $dark-secondary-text,
    300: $dark-secondary-text,
    400: $dark-secondary-text,
    500: $dark-secondary-text,
    600: $light-secondary-text,
    700: $light-secondary-text,
    800: $light-secondary-text,
    900: $light-secondary-text,
  )
);

$pidg-warn-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #f19a9a,
  300: #e77372,
  400: #f2534f,
  500: #f64234,
  600: #e83833,
  700: #d62e2e,
  800: #c92726,
  900: #ba1a1a,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$pidg-neutral-palette: (
  50: #f0f0f3,
  100: #eeeeee,
  200: #e0e0e0,
  300: #bdbdbd,
  400: #9e9e9e,
  500: #757575,
  600: #616161,
  700: #424242,
  800: #2e3133,
  900: #1a1c1e,
  contrast: (
    50: $dark-secondary-text,
    100: $dark-secondary-text,
    200: $dark-secondary-text,
    300: $dark-secondary-text,
    400: $dark-secondary-text,
    500: $dark-secondary-text,
    600: $light-secondary-text,
    700: $light-secondary-text,
    800: $light-secondary-text,
    900: $light-secondary-text,
  )
);

$pidg-neutral-variant-palette: (
  50: #eff4fa,
  100: #dde3ea,
  200: #c1c7ce,
  300: #a6acb3,
  400: #8b9198,
  500: #72787e,
  600: #595f65,
  700: #41474d,
  800: #2b3137,
  900: #161c22,
  contrast: (
    50: $dark-secondary-text,
    100: $dark-secondary-text,
    200: $dark-secondary-text,
    300: $dark-secondary-text,
    400: $dark-secondary-text,
    500: $dark-secondary-text,
    600: $light-secondary-text,
    700: $light-secondary-text,
    800: $light-secondary-text,
    900: $light-secondary-text,
  )
);

$pidg-primary: mat.define-palette($pidg-palette, 600);
$pidg-accent: mat.define-palette($pidg-palette, A200, A100, A400);
$pidg-warn: mat.define-palette($pidg-warn-palette, 900);
$pidg-typography: mat.define-legacy-typography-config(
  $font-family: 'Manrope, sans-serif',
);

$pidg-theme: mat.define-light-theme((
  color: (
    primary: $pidg-primary,
    accent: $pidg-accent,
    warn: $pidg-warn,
  ),
  typography: $pidg-typography,
));

@include mat.all-component-typographies($pidg-theme);
@include mat.core();
@include mat.core-theme($pidg-theme);
@include mat.all-component-themes($pidg-theme);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($pidg-theme);`

:root {
  --pidg-color-white: #ffffff;
  --pidg-color-black: #000000;

  --pidg-color-neutral10: #{map-get($pidg-neutral-palette, 900)}; // #1a1c1e;
  --pidg-color-neutral-variant95: #{map-get($pidg-neutral-variant-palette, 50)}; // #eff4fa;

  --pidg-color-primary: #{map-get($pidg-primary, 600)}; // #006494;
  --pidg-color-on-primary: #{map-get(map-get($pidg-primary, contrast), 600)}; // #fff;
  --pidg-color-primary-container: #{map-get($pidg-primary, 100)}; // #cbe6ff;
  --pidg-color-on-primary-container: #{map-get(map-get($pidg-primary, contrast), 100)}; // #001e30;

  --pidg-color-accent: #{map-get($pidg-accent, 100)}; // #bce9ff;

  --pidg-color-secondary-container: #{map-get($pidg-primary, A200)}; //#bbe9ff;
  --pidg-color-on-secondary-container: #{map-get(map-get($pidg-primary, contrast), A200)}; // #001f29;

  --pidg-color-success: #{map-get($pidg-tertiary-palette, 600)}; // #4c6700;
  --pidg-color-success-container: #{map-get($pidg-tertiary-palette, 50)};

  --pidg-color-error: #{map-get($pidg-warn-palette, 900)}; // #ba1a1a;
  --pidg-color-error-container: #{map-get($pidg-warn-palette, 50)}; // ???

  --pidg-color-background: #{map-get(map-get($pidg-neutral-palette, contrast), 900)}; // #fcfcff;
  --pidg-color-on-background: #{map-get($pidg-neutral-palette, 900)}; // #1a1c1e;

  --pidg-color-surface: #{map-get(map-get($pidg-neutral-palette, contrast), 900)}; // #fcfcff;
  --pidg-color-on-surface: #{map-get($pidg-neutral-palette, 900)}; // #1a1c1e;

  --pidg-color-outline: #{map-get($pidg-neutral-variant-palette, 500)}; // #72787e;
  --pidg-color-outline-variant: #cac4d0;

  --pidg-color-disabled-label: #{rgba(map-get($pidg-neutral-variant-palette, 700), 0.8)};
  --pidg-color-disabled-border: #{rgba(map-get($pidg-neutral-variant-palette, 700), 0.06)};

  --pidg-color-surface-variant: #{map-get($pidg-neutral-variant-palette, 100)}; // #dde3ea;
  --pidg-color-on-surface-variant: #{map-get($pidg-neutral-variant-palette, 700)}; // #41474d;

  --pidg-color-inverse-surface: #{map-get($pidg-neutral-palette, 800)}; // #2e3133;
  --pidg-color-inverse-on-surface: #{map-get($pidg-neutral-palette, 50)}; // #f0f0f3;

  --pidg-color-warning: #ea9700;
  --pidg-color-warning-container: #fff8e5;

  --pidg-color-grey: #a9a9a9;
  --pidg-color-yellow: #ffcc33;
  --pidg-color-orange: #ed8920;
  --pidg-color-red: #cc0000;
  --pidg-color-dark-red: #990000;
}
