/*
  Field to be commented must be positioned so that
  comment overlay can attach itself to it.
*/
.pidg-comments-field.pidg-comments-field {
  position: relative;
  overflow: visible;
}

.pidg-comments-field-absolute.pidg-comments-field-absolute {
  position: absolute;
  overflow: visible;
}

.pidg-comments-icon-bottom-right.pidg-comments-icon-bottom-right {
  position: absolute;
  bottom: calc(var(--gap) / 2);
  right: calc(var(--gap) / 2);
}
