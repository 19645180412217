/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Manrope, sans-serif;
}

input, textarea, button  {
  font-family: inherit;
}

h1 {
  margin: 0 0 var(--gap);
}

h2 {
  margin: var(--gap) 0 calc(var(--gap-small) / 2);
}

:root {
  --gap: 2rem;
  --gap-small: 1rem;
  --radius: 1rem;

  --grid-columns-count: 12;
  --button-radius: 100px;

  --overlay-backdrop-color: rgba(0, 0, 0, 0.5);

  /**
    z-index definitions
   */
  --z-index-form: 1;
  --z-index-form-nav: var(--z-index-form);

  --z-index-comments-overlay: 0;
  --z-index-comments-overlay-field: 2;
  --z-index-comments-field-icon: var(--z-index-comments-overlay-field);
  --z-index-comments-overlay-selected: 3;
  --z-index-comments-overlay-close-button: 4;

  --z-index-project-history: 2;
}

/**
  Utils
 */
.scroll-x-shadows {
  overflow-x: auto;
  overflow-y: clip;
  background-image: linear-gradient(to right, white, white),
  linear-gradient(to right, white, white),
  linear-gradient(to right, rgba(88, 88, 88, 0.3),
    rgba(0, 0, 0, 0)),
  linear-gradient(to left, rgba(88, 88, 88, 0.3),
    rgba(0, 0, 0, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
  padding: 1px; /* Workaround to enforce tables borders to be visible. Otherwise borders are cut. */
}

/**
  Layout
 */
.pidg-container {
  display: grid;
  padding: var(--gap);
  padding-bottom: 0;
  grid-gap: var(--gap);
  background-color: var(--pidg-color-neutral-variant95);
  grid-template-columns: repeat(var(--grid-columns-count), 1fr);
  grid-template-rows: 4rem auto 3.5rem 0;
  grid-auto-flow: column;
  min-height: 100vh;
  box-sizing: border-box;
}

.pidg-logo {
  grid-column: 1 / span 2;
  width: 100px;
  max-width: 100%;
  cursor: pointer;
  user-select: none;
}

.pidg-flex-align-items-center {
  display: flex;
  align-items: center;
  gap: calc(var(--gap) / 4);
}

.pidg-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pidg-position-relative {
  position: relative;
}

.pidg-overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.pidg-offset-top {
  margin-top: var(--gap);
}

.pidg-offset-bottom {
  margin-bottom: var(--gap);
}

/**
  Table
 */
.pidg-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 1rem;
  box-shadow: 0 0 0 1px var(--pidg-color-surface-variant);
}

.pidg-table .pidg-table-main {
  font-weight: 600;
}

.pidg-table .pidg-table-sub {
  padding: 1.5rem 2.5rem;
}

.pidg-table td,
.pidg-table th {
  border-left: 1px solid var(--pidg-color-surface-variant);
  border-bottom: 1px solid var(--pidg-color-surface-variant);
}

.pidg-table th {
  padding: 1rem;
  font-weight: 600;
}

.pidg-table td {
  padding: 1.5rem 1rem;
}

.pidg-table .pidg-table-first-row-important td:nth-child(1) {
  font-weight: 600;
}

.pidg-table-cell-date {
  width: 180px;
}

.pidg-table-cell-extra-small {
  width: 100px;
}

.pidg-table-cell-small {
  width: 200px;
}

.pidg-table-cell-medium {
  width: 320px;
}

.pidg-table-cell-big {
  width: 480px;
}

.pidg-table-cell-datapoint-counter {
  width: 60px;
}

.pidg-table-row-inline {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pidg-table-delete-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/**
  Print
 */
@media print {
  :host {
    background-color: var(--pidg-color-white);
    color: red !important;
  }

  .nonprintable {
    display: none;
  }

  .cdk-overlay-container {
    display: none;
  }
}

@media screen {
  pidg-form-print {
    display: none;
  }

  pidg-ppm-form-print {
    display: none;
  }
}

/**
  Form
 */
.pidg-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  position: relative;
}

.pidg-form-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}


/**
  Snack Bar
 */
.pidg-snackbar-error.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--pidg-color-error);
}

.mat-mdc-snack-bar-container.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: var(--pidg-color-white);
}


.pidg-form-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

/**
  Material Overwrites
 */

/* overwrites floating label color */
pidg-form-field .mat-mdc-form-field .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
pidg-form-field .mat-mdc-form-field .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: var(--pidg-color-black);
}

/* change floating label color when form field is invalid */
pidg-form-field .mat-mdc-form-field .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
pidg-form-field .mat-mdc-form-field .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
  color: var(--pidg-color-black);
}

.mdc-text-field--disabled .mdc-floating-label,
.mdc-text-field--disabled .mdc-text-field__input,
.mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label,
.mat-mdc-select-disabled .mat-mdc-select-value {
  color: var(--pidg-color-disabled-label);
}

/* overwrites standard value of 180px for better control*/
mat-form-field .mat-form-field-infix {
  width: 100%;
}

mat-form-field.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 24px 0 0;
}

/* overwrites mat-error to fit into application containers */
.mat-mdc-form-field-error {
  margin-top: -12px;
  margin-right: 24px;
  line-height: 1.2;
}

/* overwrites standard height of label to not cut letters */
.mat-form-field-empty.mat-form-field-label {
  min-height: 2rem;
}

/* overwrites standard line-height of input to not cut letters */
input.mat-input-element {
  line-height: 1rem;
}

.mat-select-value-text {
  line-height: 2rem;
}

/* overwrites colors of specific selected values from mat-select */
.select-option-red > .mat-mdc-select-trigger > .mat-mdc-select-value {
  color: var(--pidg-color-error);
}

.select-option-amber > .mat-mdc-select-trigger > .mat-mdc-select-value {
  color: var(--pidg-color-warning);
}

.select-option-green > .mat-mdc-select-trigger > .mat-mdc-select-value {
  color: var(--pidg-color-success);
}

/* overwrites background colors of specific selected values from mat-select */
.select-option-background-grey.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.select-option-background-grey .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: var(--pidg-color-grey);
}

.select-option-background-green.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.select-option-background-green .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: var(--pidg-color-success);
}

.select-option-background-yellow.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.select-option-background-yellow .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: var(--pidg-color-yellow);
}

.select-option-background-orange.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.select-option-background-orange .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: var(--pidg-color-orange);
}

.select-option-background-red.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.select-option-background-red .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: var(--pidg-color-red);
}

.select-option-background-dark-red.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.select-option-background-dark-red .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  background-color: var(--pidg-color-dark-red);
}

/* overrides select font-folors only those who have background red and dark-red */
.select-option-background-red .mat-mdc-select-value,
.select-option-background-dark-red .mat-mdc-select-value {
  color: var(--pidg-color-white);
}

/* overwrites colors of specific select options in from mat-select */
.select-option-red > .mdc-list-item__primary-text {
  color: var(--pidg-color-error);
}

.select-option-green > .mdc-list-item__primary-text {
  color: var(--pidg-color-success);
}

.select-option-amber > .mdc-list-item__primary-text {
  color: var(--pidg-color-warning);
}

/* shows all multi-selected values */
.all-multiselected-values mat-select.mat-mdc-select .mat-mdc-select-value,
.all-multiselected-values mat-select.mat-mdc-select .mat-mdc-select-value .mat-mdc-select-value-text {
  white-space: pre-line;
}

/* Material snack bar */
.mat-simple-snackbar-action {
  color: var(--pidg-color-white);
}

/* Material tooltip */
mat-tooltip-component .mdc-tooltip .mdc-tooltip__surface {
  background-color: var(--pidg-color-primary);
  box-sizing: border-box;
  font-size: 13px;
  padding: 10px;
  line-height: 1.25;
  max-width: 350px;
}

.mat-tooltip-line-breaks {
  white-space: pre-line;
}

/* Material button */
/* makes all buttons rounded */
button.mdc-button {
  border-radius: var(--button-radius);
}
